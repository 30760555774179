* {
	box-sizing: border-box;
}

:root {
	--default-padding: 16px;
	--header-height: 80px;
	--sticky-pos: 10px;
}

body {
	margin: calc(var(--default-padding) * 2) auto;
	line-height: 1.6;
	color: #78909c;
	font-family: 'Roboto', sans-serif;
	background-color: #fff;
	max-width: 1000px;
	padding: 0 var(--default-padding);
}

a {
	color: inherit;
}

h1,
h2 {
	font-size: 28px;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 300;
	color: #546e7a;
	margin: 0;
}

#container {
	overflow: auto;
	will-change: transform; /* make overflow fast on non-retina */
	padding: var(--default-padding);
	background-color: #eceff1;
	scroll-behavior: smooth;
}

.debug [data-sticky-sentinel-location] {
	background: #ff00a5;
	visibility: visible !important;
	opacity: 0.8;
	color: initial;
	display: flex;
	justify-content: center;
	align-items: center;
}

.debug .sticky {
	z-index: 1;
}

.sticky {
	position: sticky;
	top: var(--sticky-pos); /* Sentinels adjust based on this position. */
	height: var(--header-height);
	background: #3a5e8c;
	padding: 0 var(--default-padding);
	display: flex;
	align-items: center;
}

.sticky-item {
	background-color: #fff;
	margin-bottom: calc(var(--default-padding) * 2);
	padding: var(--default-padding);
	position: relative;
	text-align: justify;
}

.sticky-item h2 {
	color: #fff;
}

.sticky-item [stuck] {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
		0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
}

.sticky-status {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
}

header {
	max-width: 700px;
}

main {
	display: flex;
	height: 50vh;
}
